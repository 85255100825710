<template>
  <div class="py-4 text-center">
    <YscLoader class="mx-auto h-10 w-10" />
    <slot>
      <p class="mt-2">
        {{ $t('components.app_loading.loading') }}
      </p>
    </slot>
  </div>
</template>
